import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatesSubscriptionComponent } from './updates-subscription.component';



@NgModule({
	declarations: [UpdatesSubscriptionComponent], exports: [UpdatesSubscriptionComponent], imports: [CommonModule]
})
export class UpdatesSubscriptionModule { }
