import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {UpdatesSubscriptionModule} from "../updates-subscription/updates-subscription.module";
import {MatToolbarModule} from "@angular/material/toolbar";



@NgModule({
  declarations: [HeaderComponent], exports: [HeaderComponent],
	imports: [CommonModule, UpdatesSubscriptionModule, MatToolbarModule]
})
export class HeaderModule { }
