import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from "@angular/material/toolbar";
import {HeaderModule} from "./core/modules/header/header.module";

@NgModule({
  declarations: [
    AppComponent
  ], imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, MatToolbarModule, HeaderModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
